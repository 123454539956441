import React, {
  createContext,
  lazy,
  Suspense,
  useContext,
  useEffect, useRef, useState,
} from 'react';
import {createRoot} from 'react-dom/client';
import {
  ClientWidthContext, UserContext, ModalDispatchContext, ModalContext,
} from './Componentsv2/Context/ApplicationContextProvider';
import Layout from './Componentsv2/Layout/Layout';
import AbandonedCart from './Componentsv2/AbandonedCart/AbandonedCart';
import PromoBanners from './Componentsv2/PromoBanners/PromoBanners';
import PromoBannersMobile
  from './Componentsv2/PromoBannersMobile/PromoBannersMobile';
import Icon from './Componentsv2/Icon/Icon';
import Lang from './Componentsv2/Lang/Lang';
import {eventEmitter, lang, langSiteDir, pageMeta} from './common/baseData';
import ProductSlider from './Componentsv2/ProductSlider/ProductSlider';
import Product from './Componentsv2/Product/Product';
import Basket from './api/Basket';
import SeoText from './Componentsv2/SeoText/SeoText';
import RootSection from './Componentsv2/Navigation/RootSection/RootSection';
import LinkingBanners from './Componentsv2/LinkingBanners/LinkingBanners';
import Subscribtion from './Componentsv2/Subscribtion/Subscribtion';

const appRoot = createRoot(document.getElementById('app-root'));

const page = 'Main';

appRoot.render(
  <Layout page={page}>
    <HomePage/>
  </Layout>,
);

function HomePage() {
  const dispatchModal = useContext(ModalDispatchContext);
  const modals = useContext(ModalContext);

  const [sectionTreeData, setSectionTreeData] = useState(window.sectionTree || {
    rootSections: null,
    sections: null,
    popularSections: null,
    popularLinks: null,
  });

  const [popularCategoryList, setPopularCategoryList] = useState(
    window.homeCategoryList || []);

  const [articleList, setArticleList] = useState(
    window.homePageArticles || null);

  const [seoText, setSeoText] = useState(null);

  const [viewedProductList, setViewedProductList] = React.useState(
    window.viewedProducts || null);
  const [saleProductList, setSaleProductList] = React.useState(
    window.saleProducts || null);
  const [topProductList, setTopProductList] = React.useState(
    window.topProducts || null);
  const [linkingBanners, setLinkingBanners] = React.useState(
    window.homeLinkingBanners || null);

  const [promoBanners, setPromoBanners] = useState(window.promoBanners || []);

  const categoryVariables = window.homeCategoryVariables || [];

  const sectionClass = `max-w-[1600px] mx-auto desktop:px-5`;

  const clientWidth = useContext(ClientWidthContext);
  const [isMobile, setIsMobile] = useState(clientWidth < 1280);

  useEffect(() => {
    if (clientWidth < 1280) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [clientWidth]);


  useEffect(() => {
    try {
      if (pageMeta) {
        setSeoText(JSON.parse(atob(pageMeta.text)));
      }
      ;
    } catch (reason) {
      console.error(reason);
    }
  }, []);

  const popularCategoryItems = popularCategoryList.map((item, index) => (
    <li className="border border-solid min-w-[calc((100%/6)-17px)] border-grey-10 tablet:min-w-[calc(100%/4.75)] phone:min-w-[calc(100%/3.5)] 400:!min-w-[calc(100%/2.5)]"
        key={`popular-category-${index}`}>
      <a href={item.link}
         className="flex flex-col items-center justify-center gap-2 h-[121px] hover:border-transparent">
        {
          item.hasOwnProperty('menuPicture') && item.menuPicture &&
          item.menuPicture.hasOwnProperty('src') && (
            <img src={item.menuPicture.src}
                 height="48px"
                 width="56px"
                 loading={'lazy'}
                 alt={item[`name${lang.toUpperCase()}`]}
                 title={item[`name${lang.toUpperCase()}`]}
                 className="object-fill"/>
          )
        }
        <span
          className="text-grey-90 text-[14px] leading-[21px] text-center max-w-[calc(80%)]">
            {item[`name${lang.toUpperCase()}`]}
        </span>
      </a>
    </li>
  ));

  return (
    <main className={sectionClass}>
      <section className={`promo-box-container relative bg-white desktop:pt-2 desktop:flex gap-12`}>
        {
          !isMobile && (
            <nav className={`relative bg-white`}
                 onMouseEnter={event => (
                   dispatchModal({type: 'show', modalType: 'navigation'})
                 )}>
              <ul className={`w-[295px] flex flex-col grow-0 shrink-0 h-max`}>
                {
                  sectionTreeData.rootSections &&
                  Array.isArray(sectionTreeData.rootSections) &&
                  sectionTreeData.rootSections.map((item, index) => (
                    <RootSection key={index} {...item} height={'36px'}/>
                  ))
                }
              </ul>
            </nav>
          )
        }
        <div
          className={`relative flex flex-col desktop:w-[calc(100%-295px-48px)]`}>
          <AbandonedCart/>
          {
            !isMobile && Array.isArray(promoBanners) &&
            promoBanners.length > 0 && (
              <PromoBanners items={promoBanners}/>
            )
          }
        </div>
      </section>
      {
        isMobile && (
          <>
            {
              Array.isArray(promoBanners) &&
              promoBanners.length > 0 && (
                <section className="relative">
                  <PromoBannersMobile items={promoBanners}/>
                </section>
              )
            }
            <section className={`px-5 w-full mb-4 mt-10 desktop:my-4`}>
              <button type="button"
                      className="flex items-center justify-center gap-4 w-full h-[46px] bg-blue-primary border-0 rounded-2"
                      onClick={() => {
                        eventEmitter.emit('showMobileCatalog');
                      }}>
                <Icon name={'catalog-polygon-icon-v2'} className="h-4 w-4"/>
                <span className="text-[16px] text-white leading-[24px]">
                        {Lang.getMessage('catalog')}
                      </span>
              </button>
            </section>
          </>
        )
      }
      {
        Array.isArray(popularCategoryList) &&
        popularCategoryList.length > 0 && (
          <section
            className={`px-5 mb-5 desktop:mb-14 mt-5 desktop:mt-11 desktop:px-0 tablet:pl-5 tablet:pr-0 phone:pl-5 phone:pr-0`}>
            <ul
              className="invisible-scrollbar flex overflow-scroll gap-2 desktop:grid-cols-6 desktop:gap-5"
              style={{scrollbarWidth: 'none', msOverflowStyle: 'none'}}>
              {popularCategoryItems}
            </ul>
          </section>
        )
      }
      {/** блок просмотренных товаров **/
        viewedProductList !== null &&
        viewedProductList.hasOwnProperty('items') &&
        Array.isArray(viewedProductList.items) &&
        viewedProductList.items.length && (
          <ProductSlider {...viewedProductList} key="viewed-product-list">
            {
              viewedProductList.items.map((item, index) => (
                <Product {...item}
                         key={`viewed-${index}`}
                         itemKey={`viewed-${index}`}
                         gaParams={viewedProductList.gaParams}
                         last={index === viewedProductList.items.length - 1}/>
              ))
            }
          </ProductSlider>
        )
      }
      {/** блок топ товаров **/
        topProductList !== null &&
        topProductList.hasOwnProperty('items') &&
        Array.isArray(topProductList.items) &&
        topProductList.items.length && (
          <ProductSlider {...topProductList} key="top-product-list">
            {
              topProductList.items.map((item, index) => (
                <Product {...item}
                         key={`top-${index}`}
                         itemKey={`top-${index}`}
                         gaParams={topProductList.gaParams}
                         last={index === topProductList.items.length - 1}/>
              ))
            }
          </ProductSlider>
        )
      }
      {/** блок распродажа товаров **/
        saleProductList !== null &&
        saleProductList.hasOwnProperty('items') &&
        Array.isArray(saleProductList.items) &&
        saleProductList.items.length && (
          <ProductSlider {...saleProductList} key="sale-product-list">
            {
              saleProductList.items.map((item, index) => (
                <Product {...item}
                         key={`sale-${index}`}
                         itemKey={`sale-${index}`}
                         gaParams={saleProductList.gaParams}
                         last={index === saleProductList.items.length - 1}/>
              ))
            }
          </ProductSlider>
        )
      }
      {
        Array.isArray(linkingBanners) && linkingBanners.length > 0 && (
          <LinkingBanners items={linkingBanners}/>
        )
      }
      {/** список слайдеров товаров из категорий **/
        Array.isArray(categoryVariables) && categoryVariables.length &&
        (
          categoryVariables.map((variableName, index) => {
            let initialData = window[variableName];

            if (initialData && initialData.hasOwnProperty('items') &&
              Array.isArray(initialData.items) &&
              initialData.items.length >
              0) {
              return <ProductSlider {...initialData} key={`${variableName}`}>
                {
                  initialData.items.map((item, index) => (
                    <Product {...item}
                             key={`${variableName}-${index}`}
                             itemKey={`${variableName}-${index}`}
                             gaParams={initialData.gaParams}
                             last={index === initialData.items.length -
                               1}/>
                  ))
                }
              </ProductSlider>;
            }
          })
        )
      }
      {
        Array.isArray(articleList) && articleList.length && (
          <section className="my-10 flex flex-col gap-4">
            <div
              className="px-5 pb-3 flex justify-between items-baseline desktop:justify-normal desktop:gap-3 desktop:px-0">
            <span
              className="text-[16px] leading-[24px] font-medium text-grey-90 desktop:text-[24px] desktop:leading-[36px]">
                {Lang.getMessage('News from blog')}
            </span>
              <a href={`${langSiteDir}news/`}
                 className="inline-block w-auto text-blue-primary text-[14px] leading-[21px] underline desktop:text-[16px] desktop:leading-[24px] hover:no-underline">
                {Lang.getMessage('All news')}
              </a>
            </div>
            <ul
              className="px-4 flex gap-3 overflow-x-scroll scroll-smooth snap-x snap-mandatory overscroll-x-none invisible-scrollbar desktop:px-0 desktop:gap-5">
              {
                articleList.map((article, index) => (
                  <li
                    key={`article-${index}`}
                    className="flex flex-col w-[200px] desktop:w-[295px] shrink-0">
                    <picture>
                      {
                        article.picture && article.picture.hasOwnProperty('webp') &&
                        article.picture.webp && (
                          <source srcSet={article.picture.webp}
                                  type="image/webp"/>
                        )
                      }
                      {
                        article.picture && article.picture.hasOwnProperty('avif') &&
                        article.picture.avif && (
                          <source srcSet={article.picture.avif}
                                  type="image/avif"/>
                        )
                      }
                      <img src={article.picture && article.picture.hasOwnProperty('src') && article.picture.src ? article.picture.src : ''}
                           className="max-w-[100%] max-h-[100%] object-cover desktop:h-[193px]"
                           width={'295px'}
                           height={'125px'}
                           alt={article.title}
                           title={article.title}
                           loading="lazy"/>
                    </picture>
                    <a href={article.link}
                       className="line-clamp-2 mt-2 text-[14px] leading-[21px] text-grey-90 font-medium desktop:mt-3 desktop:text-[16px] desktop:leading-[24px]">
                      {article.title}
                    </a>
                    <p
                      className="line-clamp-2 mt-3 text-[12px] leading-[18px] text-grey-90 font-medium desktop:text-[14px] desktop:leading-[21px]">
                      {article.preview}
                    </p>
                    <span
                      className="mt-3 text-[11px] leading-[16px] text-grey-40 font-medium desktop:text-[14px] desktop:leading-[21px] desktop:text-grey-80">
                        {article.date}
                    </span>
                  </li>
                ))
              }
            </ul>
          </section>
        )
      }
      {seoText && (<SeoText h1={pageMeta.h1} text={seoText}/>)}
    </main>
  );
}