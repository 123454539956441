import React, { useEffect, useState } from 'react'
import Product from '../Product/Product';
import { useInView } from 'react-intersection-observer'
import { analytics } from '../../common/baseData'

function ProductSlider(props) {
  const {title, totalTitle, totalLink, children, gaParams} = props;

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true
  })

  // GA4 / sending item list view event
  useEffect(() => {
    if (!inView || !gaParams?.productIds?.length) return

    analytics.viewItemList(gaParams.productIds, gaParams.item_list_name, gaParams.item_list_id)
    .then(response => {
      analytics.view_item_list(response.events[0].params);
      console.info(response)
    })
    .catch(reason => (console.error(reason)))
  }, [inView])

  return (
    <section className={`my-10`} ref={ref}>
      <div
        className="px-5 pb-3 flex justify-between items-baseline desktop:justify-normal desktop:gap-3 desktop:px-0">
            <span
              className="text-[16px] leading-[24px] font-medium text-grey-90 desktop:text-[24px] desktop:leading-[36px]">
                {title}
            </span>
        <a href={totalLink}
           className="inline-block w-auto text-blue-primary text-[14px] leading-[21px] underline desktop:text-[16px] desktop:leading-[24px] hover:no-underline">
          {totalTitle}
        </a>
      </div>
      <ul
        className="px-5 desktop:px-0 flex overflow-x-scroll scroll-smooth snap-x snap-mandatory overscroll-x-none invisible-scrollbar">
        {children}
      </ul>
    </section>
  );
}

export default ProductSlider;